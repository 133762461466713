import {
  type DiscoverFeedFieldData,
  EarningType,
  type Platform,
  Quest,
  type QuestSection,
  QuestTileType,
  type RuleId,
  VideoUrl,
  getDiscoverFeedItemFromFieldData,
} from "@imx-tokens-ts/gems-game/domain";
import { match } from "ts-pattern";
import type { InjectableFetch } from "./types";

export type FieldDataPromoTile = {
  "analytics-id": string;
  "cta-link": string;
  name: string;
  description: string;
  "cta-text": string;
  "tile-image": {
    fileId: string;
    url: string;
    alt: string | null;
  };
  slug: string;
  caption: string;
  index: number;
};

export type FieldDataQuest = {
  manual: boolean;
  "gem-distribution-pending": boolean;
  "are-gems-distributed": boolean;
  "background-video"?: string;
  "game-link"?: string;
  "apple-store-game-link"?: string;
  "google-play-game-link"?: string;
  "layer3-link"?: string;
  "register-link"?: string;
  "start-date"?: string;
  "end-date"?: string;
  "discord-link"?: string;
  "youtube-link"?: string;
  "twitter-link"?: string;
  "support-link"?: string;
  "trading-link"?: string;
  "quiz-link"?: string;
  "verify-eligibility-link"?: string;
  "supported-platforms"?: string[];
  "gems-amount"?: number;
  "analytics-id": string;
  "game-id": string;
  "in-game-quest"?: boolean;
  type: string;
  name: string;
  slug: string;
  "estimated-completion-time"?: string;
  rules?: string;
  "custom-title"?: string;
  "custom-coming-soon-title"?: string;
  "sub-text"?: string;
  logo: {
    fileId: string;
    url: string;
    alt: string | null;
  };
  "background-image": {
    fileId: string;
    url: string;
    alt: string | null;
  };
  "dedicated-quest-page-id"?: string;
  order: number;
  cta?: string;
  "cta-icon"?: string;
};

export type FieldDataQuestSection = {
  name: string;
  description?: string;
  "quest-ids": string;
  order: number;
  promoted: boolean;
  slug: string;
};

type Item<T> = {
  id: string;
  cmsLocaleId: string;
  lastPublished: string | null;
  lastUpdated: string;
  createdOn: string;
  isArchived: boolean;
  isDraft: boolean;
  fieldData: T;
};

export type Response<T> = {
  items: Item<T>[];
};

export type GameTile = {
  id: string;
  caption: string;
  title: string;
  description: string;
  logo: string;
  link: string;
  isTrusted: boolean; // Should be used to set the rel and referrer-policy attributes of links. Trusted === sites which Immutable has some control over (e.g. Toolkit)
  buttonText: string;
};

export const getPromoTiles = async (
  fetch: InjectableFetch,
  collectionItemsUrl: URL,
): Promise<GameTile[]> => {
  const res = await fetch<Response<FieldDataPromoTile>, Error>(
    collectionItemsUrl.toString(),
    {},
  );

  return await match(res)
    .with({ ok: true }, async (res) => {
      const body = await res.json();
      return body.items
        .sort((a, b) => b.fieldData.index - a.fieldData.index)
        .map((i) => ({
          isDraft: i.isDraft,
          link: i.fieldData["cta-link"],
          buttonText: i.fieldData["cta-text"],
          description: i.fieldData.description,
          title: i.fieldData.name,
          id: i.fieldData["analytics-id"],
          caption: i.fieldData.caption,
          logo: i.fieldData["tile-image"].url,
          isTrusted: false,
        }));
    })
    .with({ ok: false }, async (res) => {
      const text = await res.text();
      throw new Error(`Error from API: ${text}`);
    })
    .exhaustive();
};

function constructUrl(url: string | undefined): URL | undefined {
  return url ? new URL(url) : undefined;
}

type GetQuestsResponse = {
  featuredQuests: Quest[];
  mainQuests: Quest[];
  sideQuests: Quest[];
  onboardingQuests: Quest[];
  seriesQuests: Quest[];
  inGameQuests: Quest[];
};

export const getQuests = async (
  fetch: InjectableFetch,
  collectionItemsUrl: URL,
): Promise<GetQuestsResponse> => {
  const res = await fetch<Response<FieldDataQuest>, Error>(
    collectionItemsUrl.toString(),
    {},
  );

  const response: GetQuestsResponse = {
    featuredQuests: [],
    mainQuests: [],
    sideQuests: [],
    onboardingQuests: [],
    seriesQuests: [],
    inGameQuests: [],
  };

  return await match(res)
    .with({ ok: true }, async (res) => {
      const body = await res.json();

      const sortedCmsQuests = [...body.items].sort(
        (a, b) => b.fieldData.order - a.fieldData.order,
      );

      // biome-ignore lint/complexity/noForEach: not too big of an array
      sortedCmsQuests.forEach((cmsQuest) => {
        const quest = getQuestFromFieldData(cmsQuest.fieldData);
        // group tiles as we go
        if (quest.type === QuestTileType.FeaturedQuest) {
          response.featuredQuests.push(quest);
        } else if (quest.type === QuestTileType.MainQuest) {
          response.mainQuests.push(quest);
        } else if (quest.type === QuestTileType.SideQuest) {
          response.sideQuests.push(quest);
        } else if (quest.type === QuestTileType.OnboardingQuest) {
          response.onboardingQuests.push(quest);
        } else if (quest.type === QuestTileType.SeriesQuest) {
          response.seriesQuests.push(quest);
        }

        if (quest.inGame) {
          response.inGameQuests.push(quest);
        }

        return quest;
      });

      return {
        featuredQuests: response.featuredQuests,
        mainQuests: response.mainQuests,
        sideQuests: response.sideQuests,
        onboardingQuests: response.onboardingQuests,
        seriesQuests: response.seriesQuests,
        inGameQuests: response.inGameQuests,
      };
    })
    .with({ ok: false }, async (res) => {
      const text = await res.text();
      throw new Error(`Error from API: ${text}`);
    })
    .exhaustive();
};

const cmsTypeToQuestType: Record<string, QuestTileType> = {
  dcf30fb508f0f16830d949d9cc303086: QuestTileType.FeaturedQuest,
  b22a4abd20b1f5d232187c877a55492b: QuestTileType.MainQuest,
  ebd4bca5320c99e0b841cd26f9a2154c: QuestTileType.SideQuest,
  a652cd65ff4bd008bd0bc8b8dc4e798e: QuestTileType.OnboardingQuest,
  "17532533be521661970ba4acee471fb9": QuestTileType.SeriesQuest,
};

const cmsAvailableToPlatform: Record<string, Platform> = {
  "6708677b7f6d7402f3959a92": "Mac",
  "670867871d0ca33138507a3e": "Windows",
  "670c4e614dcf738948ec0be4": "iOS",
  "670c4e7908c7315a9e75ca54": "Android",
  "670c4ecb6039543847e13f1d": "BrowserMobile",
  "670c4ee220d4b38fdb8461d1": "BrowserDesktop",
  "670c4ef778df10316f973138": "EpicStore",
  "670c4f0f5947824a1a21005e": "SteamStore",
};

function constructVideoUrl(videoUrl: string | undefined): VideoUrl | undefined {
  if (!videoUrl) {
    return;
  }

  try {
    return new VideoUrl(videoUrl);
  } catch (e) {
    return;
  }
}

export function getQuestFromFieldData(cmsQuestData: FieldDataQuest): Quest {
  return new Quest({
    id: cmsQuestData.slug as RuleId,
    caption: cmsQuestData.name,
    startDate: cmsQuestData["start-date"]
      ? new Date(cmsQuestData["start-date"])
      : undefined,
    endDate: cmsQuestData["end-date"]
      ? new Date(cmsQuestData["end-date"])
      : undefined,
    logo: new URL(cmsQuestData.logo.url),
    backgroundMediaUrl: new URL(cmsQuestData["background-image"].url),
    backgroundVideoUrl: constructVideoUrl(cmsQuestData["background-video"]),
    gemsAmount: cmsQuestData["gems-amount"]
      ? cmsQuestData["gems-amount"].toString()
      : "",
    analyticsId: cmsQuestData["analytics-id"],
    type: cmsTypeToQuestType[cmsQuestData.type],
    earningType: cmsQuestData.manual
      ? EarningType.OffChain
      : EarningType.OnChain,
    estimateCompletionTime: cmsQuestData["estimated-completion-time"],
    gameUrl: constructUrl(cmsQuestData["game-link"]),
    appleAppStoreUrl: constructUrl(cmsQuestData["apple-store-game-link"]),
    googlePlayUrl: constructUrl(cmsQuestData["google-play-game-link"]),
    layer3Url: constructUrl(cmsQuestData["layer3-link"]),
    registerUrl: constructUrl(cmsQuestData["register-link"]),
    discordUrl: constructUrl(cmsQuestData["discord-link"]),
    supportUrl: cmsQuestData["support-link"]
      ? constructUrl(cmsQuestData["support-link"])
      : constructUrl(cmsQuestData["discord-link"]),
    youtubeUrl: constructUrl(cmsQuestData["youtube-link"]),
    twitterUrl: constructUrl(cmsQuestData["twitter-link"]),
    tradingUrl: constructUrl(cmsQuestData["trading-link"]),
    inGame: cmsQuestData["in-game-quest"],
    quizUrl: constructUrl(cmsQuestData["quiz-link"]),
    verifyEligibilityUrl: constructUrl(cmsQuestData["verify-eligibility-link"]),
    rules: cmsQuestData.rules
      ? {
          items: cmsQuestData.rules.split("\n").map((r) => r.trim()),
        }
      : undefined,
    customTitle: cmsQuestData["custom-title"]
      ? cmsQuestData["custom-title"].split("\n").map((t) => t.trim())
      : undefined,
    customComingSoonTitle: cmsQuestData["custom-coming-soon-title"]
      ? cmsQuestData["custom-coming-soon-title"]
          .split("\n")
          .map((t) => t.trim())
      : undefined,
    subtext: cmsQuestData["sub-text"]
      ? cmsQuestData["sub-text"].split("\n").map((t) => t.trim())
      : undefined,
    gameId: cmsQuestData["game-id"],
    isGemDistributionPending: !cmsQuestData["are-gems-distributed"],
    availableOn: (cmsQuestData["supported-platforms"] || []).map(
      (p) => cmsAvailableToPlatform[p],
    ),
    dedicatedQuestPageId: cmsQuestData["dedicated-quest-page-id"],
    ctaText: cmsQuestData.cta,
    ctaIcon: cmsQuestData["cta-icon"],
  });
}

export const getQuestSections = async (
  fetch: InjectableFetch,
  collectionItemsUrl: URL,
): Promise<QuestSection[]> => {
  const res = await fetch<Response<FieldDataQuestSection>, Error>(
    collectionItemsUrl.toString(),
    {},
  );

  return await match(res)
    .with({ ok: true }, async (res) => {
      const body = await res.json();
      return body.items
        .sort((a, b) => b.fieldData.order - a.fieldData.order)
        .map((i) => ({
          title: i.fieldData.name,
          description: i.fieldData.description,
          questIDs: i.fieldData["quest-ids"]
            ? i.fieldData["quest-ids"].split("\n").map((id) => id.trim())
            : [],
          promoted: i.fieldData.promoted,
          slug: i.fieldData.slug,
        }));
    })
    .with({ ok: false }, async (res) => {
      const text = await res.text();
      throw new Error(`Error from API: ${text}`);
    })
    .exhaustive();
};

export const getDiscoverFeed = async (
  fetch: InjectableFetch,
  collectionItemsUrl: URL,
) => {
  const res = await fetch<Response<DiscoverFeedFieldData>, Error>(
    collectionItemsUrl.toString(),
    {},
  );

  return await match(res)
    .with({ ok: true }, async (res) => {
      const body = await res.json();
      return body.items
        .map((item) => getDiscoverFeedItemFromFieldData(item.fieldData))
        .filter((item) => item !== undefined);
    })
    .with({ ok: false }, async (res) => {
      const text = await res.text();
      throw new Error(`Error from API: ${text}`);
    })
    .exhaustive();
};
