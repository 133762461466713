import { isFuture, isPast } from "date-fns";
import { type QuestTileFilterOption, questFilterOptions } from "./FilterType";
import type { LatestEarnings, RuleId } from "./LatestEarnings";
import type { QuestRules } from "./QuestRules";
import { QuestTileType } from "./TileType";
import type { VideoUrl } from "./VideoUrl";
import {
  type QuestMediaType,
  filterQuest,
  formatDate,
  getDescriptionFromDates,
  isNowInBetween,
} from "./utils";

export enum EarningType {
  OnChain = "OnChain",
  OffChain = "OffChain",
}

export type Platform =
  | "Mac"
  | "Windows"
  | "iOS"
  | "Android"
  | "BrowserMobile"
  | "BrowserDesktop"
  | "EpicStore"
  | "SteamStore";

export type AvailableOn = Platform[];

export type QuestProps = {
  id: RuleId;
  gameId?: string;
  customTitle?: string[];
  customComingSoonTitle?: string[];
  caption: string;
  subtext?: string[];
  startDate?: Date;
  endDate?: Date;
  logo: QuestMediaType | URL;
  backgroundMediaUrl: QuestMediaType | URL;
  backgroundVideoUrl?: VideoUrl;
  gemsAmount: string;
  analyticsId: string;
  type: QuestTileType;
  earningType: EarningType;
  gameUrl?: URL;
  googlePlayUrl?: URL;
  appleAppStoreUrl?: URL;
  discordUrl?: URL;
  youtubeUrl?: URL;
  twitterUrl?: URL;
  telegramUrl?: URL;
  registerUrl?: URL;
  supportUrl?: URL;
  layer3Url?: URL;
  inGame?: boolean;
  tradingUrl?: URL;
  quizUrl?: URL;
  verifyEligibilityUrl?: URL;
  estimateCompletionTime?: string;
  isGemDistributionPending?: boolean;
  availableOn?: AvailableOn;
  description?: string[];
  rules?: QuestRules;
  dedicatedQuestPageId?: string;
  ctaText?: string;
  ctaIcon?: string;
};

export type Action =
  | "RegisterNow"
  | "Support"
  | "PlayGame"
  | "GooglePlay"
  | "AppleAppStore"
  | "Discord"
  | "Youtube"
  | "Twitter"
  | "Telegram"
  | "Rules"
  | "Layer3"
  | "TradeNow"
  | "TakeQuiz"
  | "VerifyEligibility";

export type QuestActions = {
  [key in Action]?: URL;
};

export class Quest {
  public id: RuleId;
  public gameId?: string;
  public customTitle?: string[];
  public customComingSoonTitle?: string[];
  public caption: string;
  public subtext?: string[];
  public startDate: Date | undefined;
  public endDate: Date | undefined;
  public inGame?: boolean;
  // it uses questMedia to create URl which needs access to appConfig that is not possible to access in lib
  public logo: QuestMediaType | URL;
  public backgroundMediaUrl: QuestMediaType | URL;
  public backgroundVideoUrl?: VideoUrl;
  public gemsAmount: string;
  public analyticsId: string;
  public type: QuestTileType;
  public earningType: EarningType;
  public description?: string[];
  public rules?: QuestRules;
  public combinedRules?: string[];
  public isGemDistributionPending?: boolean;
  public estimateCompletionTime?: string;
  public actions: QuestActions;
  public availableOn?: AvailableOn;
  public dedicatedQuestPageId?: string;
  public ctaText?: string;
  public ctaIcon?: string;

  constructor(quest: QuestProps) {
    this.id = quest.id;
    this.customTitle = quest.customTitle;
    this.customComingSoonTitle = quest.customComingSoonTitle;
    this.caption = quest.caption;
    this.subtext = quest.subtext;
    this.startDate = quest.startDate;
    this.endDate = quest.endDate;
    this.logo = quest.logo;
    this.backgroundMediaUrl = quest.backgroundMediaUrl;
    this.backgroundVideoUrl = quest.backgroundVideoUrl;
    this.gameId = quest.gameId;
    this.inGame = quest.inGame;
    this.gemsAmount = quest.gemsAmount;
    this.analyticsId = quest.analyticsId;
    this.type = quest.type;
    this.earningType = quest.earningType;
    this.description = quest.description;
    this.dedicatedQuestPageId = quest.dedicatedQuestPageId;
    this.ctaText = quest.ctaText;
    this.ctaIcon = quest.ctaIcon;
    if (quest.isGemDistributionPending !== undefined) {
      this.isGemDistributionPending = quest.isGemDistributionPending;
    } else {
      this.isGemDistributionPending = quest.earningType !== EarningType.OnChain;
    }

    this.estimateCompletionTime = quest.estimateCompletionTime;
    if (quest.rules) {
      this.rules = quest.rules;
      this.combinedRules = this.getCombinedRules();
    }

    this.actions = {
      RegisterNow: quest.registerUrl,
      Support: quest.supportUrl,
      PlayGame: quest.gameUrl,
      Discord: quest.discordUrl,
      Youtube: quest.youtubeUrl,
      Twitter: quest.twitterUrl,
      Telegram: quest.telegramUrl,
      Layer3: quest.layer3Url,
      GooglePlay: quest.googlePlayUrl,
      AppleAppStore: quest.appleAppStoreUrl,
      TradeNow: quest.tradingUrl,
      TakeQuiz: quest.quizUrl,
      VerifyEligibility: quest.verifyEligibilityUrl,
    };

    this.availableOn = quest.availableOn;
  }

  get combinedDescription() {
    let descriptionLines: string[] = [];
    if (this.subtext && this.subtext.length > 0) {
      descriptionLines = [...this.subtext];
    }

    if (this.description && this.description.length > 0) {
      descriptionLines = [...this.description];
    }

    const dateDescription = getDescriptionFromDates(
      this.startDate,
      this.endDate,
    );
    if (dateDescription) {
      descriptionLines.push(dateDescription);
    }

    if (this.hasEnded() && this.isGemDistributionPending) {
      descriptionLines.push("Gems yet to be distributed.");
    }

    return descriptionLines;
  }

  getCombinedRules() {
    const combinedRules = [];
    if (this.startDate) {
      const started = this.isLive() || this.hasEnded() ? "Started" : "Starts";
      combinedRules.push(`${started}: ${formatDate(this.startDate)}`);
    }

    if (this.endDate) {
      const ended = this.hasEnded() ? "Ended" : "Ends";
      combinedRules.push(`${ended}: ${formatDate(this.endDate)}`);
    }

    if (this.rules) {
      combinedRules.push(...this.rules.items);
    }

    return combinedRules;
  }

  cloneWithOverrides(overrides: Partial<QuestProps>) {
    return new Quest({
      ...this,
      appleAppStoreUrl: this.actions.AppleAppStore,
      discordUrl: this.actions.Discord,
      gameUrl: this.actions.PlayGame,
      googlePlayUrl: this.actions.GooglePlay,
      layer3Url: this.actions.Layer3,
      registerUrl: this.actions.RegisterNow,
      supportUrl: this.actions.Support,
      telegramUrl: this.actions.Telegram,
      twitterUrl: this.actions.Twitter,
      youtubeUrl: this.actions.Youtube,
      ...overrides,
    });
  }

  isFeatured() {
    return this.type === QuestTileType.FeaturedQuest;
  }

  isSideQuest() {
    return this.type === QuestTileType.SideQuest;
  }

  isMainQuest() {
    return this.type === QuestTileType.MainQuest;
  }

  isOnboardingQuest() {
    return this.type === QuestTileType.OnboardingQuest;
  }

  hasAction(action: Action) {
    return !!this.actions[action];
  }

  isImmutablePlayLink() {
    return (
      this.hasAction("PlayGame") &&
      this.actions.PlayGame?.hostname === "play.immutable.com"
    );
  }

  isTakeQuizQuest() {
    return this.hasAction("TakeQuiz");
  }

  hasCompleted(latestEarnings: LatestEarnings | undefined) {
    return latestEarnings ? latestEarnings.isQuestComplete(this.id) : false;
  }

  shouldShowSupport() {
    return (
      this.hasEnded() &&
      this.hasAction("Support") &&
      !this.isGemDistributionPending &&
      this.isOffChain()
    );
  }

  isOnChain() {
    return this.earningType === EarningType.OnChain;
  }

  isOffChain() {
    return this.earningType === EarningType.OffChain;
  }

  isLive() {
    if (!this.startDate) {
      return false;
    }
    return !this.endDate
      ? isPast(this.startDate)
      : isNowInBetween(this.startDate, this.endDate);
  }

  isComingSoon() {
    return (
      (this.startDate && isFuture(this.startDate)) ||
      (!this.startDate && !this.hasEnded())
    );
  }

  hasEnded() {
    return !this.endDate ? false : isPast(this.endDate);
  }

  getFilterOptions(
    latestEarnings: LatestEarnings | undefined,
  ): Set<QuestTileFilterOption> {
    return questFilterOptions.reduce(
      (acc, filter) =>
        filterQuest(this, filter, latestEarnings) ? acc.add(filter) : acc,
      new Set<QuestTileFilterOption>(),
    );
  }

  get title(): string[] {
    if (this.isComingSoon() && this.customComingSoonTitle)
      return this.customComingSoonTitle;
    if (this.customTitle) return this.customTitle;
    if (this.isComingSoon()) return ["Coming Soon.", `${this.caption} Quest.`];
    return [`Earn ${this.gemsAmount} Gems.`, `Play ${this.caption}.`];
  }
}
