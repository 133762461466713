import { LoadingOverlay } from "@biom3/react";

type Props = {
  loading: boolean;
  text: string | string[];
};

export const LoadingModal = ({ loading, text }: Props) => (
  <LoadingOverlay visible={loading}>
    <LoadingOverlay.Content sx={{ alignItems: "center" }}>
      <LoadingOverlay.Content.LoopingText
        text={Array.isArray(text) ? text : [text]}
      />
    </LoadingOverlay.Content>
  </LoadingOverlay>
);
