export enum QuestTileType {
  FeaturedQuest = "FeaturedQuest",
  SideQuest = "SideQuest",
  MainQuest = "MainQuest",
  OnboardingQuest = "OnboardingQuest",
  SeriesQuest = "SeriesQuest",
  PromoTile = "PromoTile",
}

export enum PromoTileType {
  PromoTile = "PromoTile",
}
