import { add, sub } from "date-fns";
import { RuleId } from "./LatestEarnings";
import { EarningType, type Platform, Quest, type QuestProps } from "./Quest";
import type { QuestRules } from "./QuestRules";
import { QuestTileType } from "./TileType";
import type { VideoUrl } from "./VideoUrl";

export class QuestFactory {
  private quest: Quest;

  constructor() {
    this.quest = new Quest({
      ...this.getDefaults(),
    });
    //biome-ignore lint/correctness/noConstructorReturn: <explanation>
    return this;
  }

  public id(id: RuleId) {
    return this.createWithOverrides({ id });
  }

  public appleAppStoreUrl(url: URL | undefined) {
    return this.createWithOverrides({ appleAppStoreUrl: url });
  }

  public googlePlayUrl(url: URL | undefined) {
    return this.createWithOverrides({ googlePlayUrl: url });
  }

  public registerUrl(url: URL | undefined) {
    return this.createWithOverrides({ registerUrl: url });
  }

  public gameUrl(url: URL | undefined) {
    return this.createWithOverrides({ gameUrl: url });
  }

  public discordUrl(url: URL | undefined) {
    return this.createWithOverrides({ discordUrl: url });
  }

  public youtubeUrl(url: URL | undefined) {
    return this.createWithOverrides({ youtubeUrl: url });
  }

  public twitterUrl(url: URL | undefined) {
    return this.createWithOverrides({ twitterUrl: url });
  }

  public supportUrl(url: URL | undefined) {
    return this.createWithOverrides({ supportUrl: url });
  }

  public layer3Url(url: URL | undefined) {
    return this.createWithOverrides({ layer3Url: url });
  }

  public tradingUrl(url: URL | undefined) {
    return this.createWithOverrides({ tradingUrl: url });
  }

  public quizUrl(url: URL | undefined) {
    return this.createWithOverrides({ quizUrl: url });
  }

  public verifyEligibilityUrl(url: URL | undefined) {
    return this.createWithOverrides({ verifyEligibilityUrl: url });
  }

  public description(description: string[]) {
    return this.createWithOverrides({ description });
  }

  public customTitle(customTitle: string[] | undefined) {
    return this.createWithOverrides({ customTitle });
  }

  public customComingSoonTitle(customComingSoonTitle: string[] | undefined) {
    return this.createWithOverrides({ customComingSoonTitle });
  }

  public subtext(subtext: string[] | undefined) {
    return this.createWithOverrides({ subtext });
  }

  public availableOn(availableOn: Platform[]) {
    return this.createWithOverrides({ availableOn });
  }

  public estimateCompletionTime(estimateCompletionTime: string) {
    return this.createWithOverrides({ estimateCompletionTime });
  }

  public dedicatedQuestPageId(dedicatedQuestPageId: string) {
    return this.createWithOverrides({ dedicatedQuestPageId });
  }

  public offChain() {
    return this.createWithOverrides({ earningType: EarningType.OffChain });
  }

  public gemDistributionPending(isPending: boolean) {
    return this.createWithOverrides({ isGemDistributionPending: isPending });
  }

  public onChain() {
    return this.createWithOverrides({ earningType: EarningType.OnChain });
  }

  public featured() {
    return this.createWithOverrides({ type: QuestTileType.FeaturedQuest });
  }

  public side() {
    return this.createWithOverrides({ type: QuestTileType.SideQuest });
  }

  public main() {
    return this.createWithOverrides({ type: QuestTileType.MainQuest });
  }

  public onboarding() {
    return this.createWithOverrides({ type: QuestTileType.OnboardingQuest });
  }

  public live() {
    return this.createWithOverrides({
      startDate: sub(new Date(), { days: 2 }),
      endDate: add(new Date(), { days: 10 }),
    });
  }

  public comingSoon() {
    return this.createWithOverrides({
      startDate: add(new Date(), { days: 5 }),
      endDate: add(new Date(), { days: 10 }),
    });
  }

  public ended() {
    return this.createWithOverrides({
      startDate: sub(new Date(), { days: 10 }),
      endDate: sub(new Date(), { days: 5 }),
    });
  }

  public startDate(date: Date | undefined) {
    return this.createWithOverrides({ startDate: date });
  }

  public endDate(date: Date | undefined) {
    return this.createWithOverrides({ endDate: date });
  }

  public rules(rules: QuestRules) {
    return this.createWithOverrides({ rules });
  }

  public backgroundVideoUrl(url: VideoUrl | undefined) {
    return this.createWithOverrides({ backgroundVideoUrl: url });
  }

  public instance(): Quest {
    return this.quest;
  }

  private createWithOverrides(overrides: Partial<QuestProps>) {
    const newQuestWithOverrides = this.quest.cloneWithOverrides(overrides);
    this.quest = newQuestWithOverrides;
    return this;
  }

  private getDefaults(): QuestProps {
    return {
      id: RuleId.SPACE_NATION_LAYER3,
      caption: "Space Nation",
      customTitle: ["Earn 150 Gems + $OIK.", "Do Space Nation Main Quest."],
      startDate: new Date("2024-08-01T00:00:00Z"),
      endDate: new Date("2024-08-16T00:00:00Z"),
      gemsAmount: "150",
      backgroundMediaUrl: new URL(
        "https://cdn.prod.website-files.com/6690d3b7585fd4942634f0fe/67649020dcab1dd1d64a5561_Background%20-%20Feature%20(2).webp",
      ),
      logo: "space-nation-logo.webp" as const,
      gameUrl: new URL("https://game.com"),
      analyticsId: "SpaceNationLayer3",
      type: QuestTileType.FeaturedQuest,
      earningType: EarningType.OnChain,
    };
  }
}
