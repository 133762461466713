import { APP_GLOBAL_SX, CUSTOM_BIOME_THEME } from "@/constants";
import { BiomeCombinedProviders } from "@biom3/react";
import type { PropsWithChildren } from "react";

export const BiomeProvider = ({ children }: PropsWithChildren) => (
  <BiomeCombinedProviders
    skipFontLoad
    globalSx={APP_GLOBAL_SX}
    theme={CUSTOM_BIOME_THEME}
  >
    {children}
  </BiomeCombinedProviders>
);
