import type { VideoMimeTypes } from "@biom3/react";

export class VideoUrl {
  public readonly url: URL;
  public readonly mimeType: VideoMimeTypes;

  constructor(urlStr: string) {
    const url = new URL(urlStr);
    const mimeType = this.getMimeTypeFromExtension(url);
    if (!mimeType) {
      throw new Error("Invalid video URL");
    }
    this.mimeType = mimeType;
    this.url = url;
  }

  getMimeTypeFromExtension(url: URL): VideoMimeTypes | undefined {
    if (!url) {
      return;
    }
    // Create a mapping of file extensions to MIME types
    const mimeTypes: { [key: string]: VideoMimeTypes } = {
      mp4: "video/mp4",
      webm: "video/webm",
      m3u8: "application/vnd.apple.mpegurl",
    };

    // Extract file extension from the URL (case insensitive)
    const fileExtension = url.pathname.split(".").pop();

    if (!fileExtension) {
      return;
    }

    // Check if the extension is in the mimeTypes object
    return mimeTypes[fileExtension.toLowerCase()];
  }
}
