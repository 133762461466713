import { usePassportProvider } from "@/context";
import { notifyError } from "@/utils/monitoring";
import { trackLegacyEvent } from "@analytics";
import { useState } from "react";

export type LoginState = "pending" | "loggingIn" | "ready" | "authenticated";

const USER_TRIGGERED_ERRORS = [
  "User did not authorize the request",
  "Popup closed by user",
];

const analyticsProps = {
  screen: "Login",
  userJourney: "Profile",
};

export const usePassportLogin = () => {
  const [loggingIn, setLoggingIn] = useState(false);
  const { triggerLogin, triggerLoginWithCallback } = usePassportProvider();

  const login = async () => {
    try {
      trackLegacyEvent({
        ...analyticsProps,
        action: "Request",
        control: "Click",
        controlType: "Button",
      });
      trackLegacyEvent({ ...analyticsProps, action: "Started" });
      setLoggingIn(true);
      await triggerLogin();
    } catch (error) {
      trackLegacyEvent({
        ...analyticsProps,
        action: "Failed",
        control: "Click",
        controlType: "Button",
      });

      const err = error as Error;
      if (!err.message || !USER_TRIGGERED_ERRORS.includes(err.message)) {
        notifyError(error, "login");
      }
      trackLegacyEvent({ ...analyticsProps, action: "Failed" });
    } finally {
      setLoggingIn(false);
    }
  };

  const loginWithCallback = async (callback: () => Promise<void>) => {
    try {
      setLoggingIn(true);
      await triggerLoginWithCallback(callback);
    } catch (error) {
      notifyError(error, "loginWithCallback");
    } finally {
      setLoggingIn(false);
    }
  };

  return { loading: loggingIn, login, loginWithCallback };
};
