import { VideoUrl } from "./VideoUrl";

export enum DiscoverFeedToCMSType {
  Video = "3889137ad6366322b185a909de3ef69a",
  Quiz = "3497a520053a8e3598eed72bc559527e",
  CTA = "b5a8515fd476d56cff5427b32fac761f",
}

export enum QuizHeaderToCMSType {
  Image = "92ad1b2f6fa1e1bdbf2a7d685469d10e",
  Video = "814cd153ee5bedf9795cb98c8ebf3bb0",
}

export interface BaseFieldData {
  name: string; // item id
  slug: string; // quest id
  "gems-earnable-2": number;
  priority: number;
  "quest-complete-popup-text"?: string;
}

interface File {
  fileId: string;
  url: string;
  alt: string | null;
}

export interface CTAFieldData extends BaseFieldData {
  "quest-type"?: DiscoverFeedToCMSType.CTA;
  "cta-image": File;
}

export interface VideoFieldData extends BaseFieldData {
  "game-id": string;
  "game-name": string;
  "quest-type": DiscoverFeedToCMSType.Video;
  "video-title"?: string;
  "cta-url"?: string;
  "cta-label"?: string;
  "video-url"?: string;
}

export interface QuizFieldData extends BaseFieldData {
  "game-id": string;
  "game-name": string;
  "quest-type": DiscoverFeedToCMSType.Quiz;
  "quiz-header-url": string;
  "quiz-header-initial-image"?: File;
  "quiz-header-answered-image"?: File;
  "quiz-answers"?: string;
  "quiz-correct-answers"?: string;
  "quiz-header-type"?: QuizHeaderToCMSType.Video | QuizHeaderToCMSType.Image;
  "quiz-logo"?: File;
  "quiz-question"?: string;
}

export type DiscoverFeedFieldData =
  | VideoFieldData
  | QuizFieldData
  | CTAFieldData;

export type Game = {
  id: string; // e.g. 1234-125151-125-1-51-5 - used for following. TODO: Where do we get this from?
  name: string; // e.g. Dark Machines
};

export type CTA = {
  description: string; // e.g. "Find out more"
  url: string; // e.g. https://mycoolgame.com
};

export type QuizHeader =
  | {
      url: VideoUrl | undefined;
      type: "video";
    }
  | {
      initialImage: URL | undefined;
      answeredImage: URL | undefined;
      type: "image";
    };

export type FeedQuest = {
  questId: string;
  gemsEarnable: number;
};

export type BaseTemplate = {
  id: string;
  quest: FeedQuest;
  priority: number;
  questCompletePopupText?: string;
};

export type CTATemplate = BaseTemplate & {
  type: "cta";
  ctaImage: URL | undefined;
};

export type RewardTemplate = BaseTemplate & {
  type: "reward";
  rewardBg: URL | undefined;
  cta: CTA | undefined;
};

export type VideoTemplate = BaseTemplate & {
  game: Game;

  // Video specific reqs
  type: "video"; // Discriminator
  title: string | undefined; // e.g. "We think you'll like"
  videoUrl: VideoUrl;
  cta: CTA | undefined;

  quest: FeedQuest;
};

export type QuizTemplate = BaseTemplate & {
  game: Game;

  // Quiz specific reqs
  type: "quiz"; // Discriminator
  logoUrl: URL | undefined;
  header: QuizHeader;
  question: string;
  answers: string[];
  correctAnswers: number[]; // TODO: Does not prevent FE hax to get the right answers. Suggestion: update BE to validate the answers.
};

export type DiscoverFeedItem =
  | VideoTemplate
  | QuizTemplate
  | CTATemplate
  | RewardTemplate
  | undefined;
export type DiscoverFeed = DiscoverFeedItem[];

const toCTATemplate = (fieldData: CTAFieldData): CTATemplate | undefined => {
  if (!fieldData["cta-image"]) {
    console.warn("CTA template is missing required fields", fieldData);
    return undefined;
  }

  return {
    id: fieldData.slug,
    type: "cta",
    questCompletePopupText: fieldData["quest-complete-popup-text"],
    ctaImage: new URL(fieldData["cta-image"].url),
    quest: {
      questId: fieldData.slug,
      gemsEarnable: fieldData["gems-earnable-2"],
    },
    priority: fieldData.priority,
  };
};

const toVideoTemplate = (
  fieldData: VideoFieldData,
): VideoTemplate | undefined => {
  if (!fieldData["video-url"]) {
    console.warn("Video template is missing required fields", fieldData);
    return undefined;
  }

  return {
    id: fieldData.slug,
    game: {
      id: fieldData["game-id"],
      name: fieldData["game-name"],
    },
    type: "video",
    title: fieldData["video-title"],
    questCompletePopupText: fieldData["quest-complete-popup-text"],
    videoUrl: new VideoUrl(fieldData["video-url"]),
    cta:
      !fieldData["cta-url"] || !fieldData["cta-label"]
        ? undefined
        : {
            description: fieldData["cta-label"],
            url: fieldData["cta-url"],
          },
    quest: {
      questId: fieldData.slug,
      gemsEarnable: fieldData["gems-earnable-2"],
    },
    priority: fieldData.priority,
  };
};

const toQuizTemplate = (fieldData: QuizFieldData): QuizTemplate | undefined => {
  // TODO: Better type safety for fields
  if (
    !fieldData["quiz-question"] ||
    !fieldData["quiz-answers"] ||
    !fieldData["quiz-correct-answers"] ||
    fieldData["quiz-answers"].split(", ").length === 0 ||
    fieldData["quiz-correct-answers"].split(", ").length === 0
  ) {
    console.warn("Quiz template is missing required fields", fieldData);
    return undefined;
  }

  return {
    id: fieldData.slug,
    game: {
      id: fieldData["game-id"],
      name: fieldData["game-name"],
    },
    type: "quiz",
    questCompletePopupText: fieldData["quest-complete-popup-text"],
    logoUrl: fieldData["quiz-logo"]
      ? new URL(fieldData["quiz-logo"].url)
      : undefined,
    header:
      fieldData["quiz-header-type"] === QuizHeaderToCMSType.Video
        ? {
            type: "video",
            url: new VideoUrl(fieldData["quiz-header-url"]),
          }
        : {
            type: "image",
            initialImage: fieldData["quiz-header-initial-image"]
              ? new URL(fieldData["quiz-header-initial-image"].url)
              : undefined,
            answeredImage: fieldData["quiz-header-answered-image"]
              ? new URL(fieldData["quiz-header-answered-image"].url)
              : undefined,
          },
    question: fieldData["quiz-question"],
    answers: fieldData["quiz-answers"].split(", ").map((a) => a.trim()),
    correctAnswers: fieldData["quiz-correct-answers"]
      ? fieldData["quiz-correct-answers"]
          .split(", ")
          .map((a) => Number.parseInt(a.trim()))
      : [],
    quest: {
      questId: fieldData.slug,
      gemsEarnable: fieldData["gems-earnable-2"],
    },
    priority: fieldData.priority,
  };
};

export const getDiscoverFeedItemFromFieldData = (
  fieldData: DiscoverFeedFieldData,
): DiscoverFeedItem => {
  switch (fieldData["quest-type"]) {
    case DiscoverFeedToCMSType.Video:
      return toVideoTemplate(fieldData);
    case DiscoverFeedToCMSType.Quiz:
      return toQuizTemplate(fieldData);
    case DiscoverFeedToCMSType.CTA:
      return toCTATemplate(fieldData);
    default:
      return undefined;
  }
};
