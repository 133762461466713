import type { Earning } from "./Earning";

// TODO: Why is this an enum?? Why aren't we matching against DB IDs????
// Need a new API to return all user's completed events, with IDs matching what is in Webflow/Prismic/Hard-coded

export enum RuleId {
  TRADE_ZKEVM = "buy-and-earn",
  TOMB_OF_HEROES = "tomb-of-heroes-quest",
  ILLUVIUM = "illuvium-quest",
  BABY_SHARK_UNIVERSE = "baby-shark-universe-quest",
  HUNTERS_ON_CHAIN = "hunters-on-chain-quest",
  MEDIEVAL_EMPIRES = "medieval-empires-quest",
  MEDIEVAL_EMPIRES_LEVEL8 = "medieval-empires-level-eight-quest",
  SPACE_NATION_LAYER3 = "space-nation-layer3-quest",
  SET_UP_PASSPORT = "set-up-passport-quest",
  SOMNIS = "somnis-quest",
  POOL_MASTERS = "pool-masters-quest",
  TREEVERSE = "treeverse-quest",
  COUNTER_FIRE = "counter-fire-quest",
  RAVEN_QUEST = "raven-quest-quest",
  POOL_MASTERS_MANUAL = "pool-masters-manual-quest",
  PARADISE_TYCOON = "paradise-tycoon-quest",
  IMMORTAL_RISING = "immortal-rising-quest",
  BATTLE_BEARS = "battle-bears-quest",
  REBEL_BOTS = "rebel-bots-quest",
  MONSTER_TYCOON = "monster-tycoon-quest",
  SHARDBOUND = "shardbound-quest",
  MYSTERY_SOCIETY_HALLOWEEN_TOURNAMENT = "mystery-society-halloween-tournament-quest",
  BLADE_OF_GOD_X_QUEST = "blade-of-god-x-quest",
  DAILY_GEMS_CLAIM = "daily-gems-claim",
  RAVEN_QUEST_INGAME = "raven-quest-ingame-quest",
  SOMINS_RUMBLE_RUSH_INGAME = "somnis-rumble-rush-main-quest",
  IMMORTAL_RISING_2_CHAPTER_ONE = "immortal-rising-2-chapter-one-quest",
  SIGNUP_REWARD = "sign-up-reward",
  TOKYO_BEAST = "tokyo-beast-quest",
  RAVEN_QUEST_BUY_QUEST_TOKEN = "rq-mar-2025-tge-sale",
  RAVEN_QUEST_CHAPTER_ONE = "raven-quest-series-chapter-one",
  RAVEN_QUEST_CHAPTER_TWO = "f8dc910f-6fdb-4b16-8d3e-d2586b5d3b2b-series-chapter-two",
  RAVEN_QUEST_CHAPTER_THREE = "f8dc910f-6fdb-4b16-8d3e-d2586b5d3b2b-series-chapter-three",
  RAVEN_QUEST_CHAPTER_FOUR = "f8dc910f-6fdb-4b16-8d3e-d2586b5d3b2b-series-chapter-4",
  RAVEN_QUEST_CHAPTER_FIVE = "f8dc910f-6fdb-4b16-8d3e-d2586b5d3b2b-series-chapter-5",
  RAVEN_QUEST_CHAPTER_SIX = "f8dc910f-6fdb-4b16-8d3e-d2586b5d3b2b-series-chapter-6",
  RAVEN_QUEST_BONUS = "f8dc910f-6fdb-4b16-8d3e-d2586b5d3b2b-series-bonus",
  NORMAN_IN_METALAND = "norman-in-metaland-quest",
  GALAXY_OF_LEMURIA = "galaxy-of-lemuria-quest",
}

export class LatestEarnings {
  public earnings: Earning[];
  private mappedEarnings: Map<RuleId, Earning>;

  constructor(earnings: Earning[]) {
    this.earnings = earnings;
    this.mappedEarnings = new Map();
    // biome-ignore lint/complexity/noForEach: not a big array
    this.earnings.forEach((earning) =>
      this.mappedEarnings.set(earning.ruleId, earning),
    );
  }

  public isQuestComplete(ruleId: RuleId): boolean {
    return this.mappedEarnings.has(ruleId);
  }

  public getEarning(ruleId: RuleId): Earning | undefined {
    return this.mappedEarnings.get(ruleId);
  }

  public cloneUpdate(updatedEarning: Earning): LatestEarnings {
    const earning = this.getEarning(updatedEarning.ruleId);
    if (!earning) {
      return new LatestEarnings([...this.earnings, updatedEarning]);
    }

    const updatedEarnings = this.earnings.map((e) =>
      e.ruleId === updatedEarning.ruleId ? updatedEarning : e,
    );

    return new LatestEarnings(updatedEarnings);
  }

  public lastEarnedForRule(ruleId: RuleId): Date | undefined {
    const earning = this.mappedEarnings.get(ruleId);
    return earning?.timestamp;
  }

  public totalEarningsForRule(ruleId: RuleId): number {
    const earning = this.mappedEarnings.get(ruleId);
    return earning?.total || 0;
  }
}
