export enum PromoTileFilterOption {
  All = "All",
  PlayNow = "Play Now",
  FreeMints = "Free Mints",
  IMXRewards = "$IMX Rewards",
}

export enum QuestTileFilterOption {
  All = "All",
  Live = "Live",
  ComingSoon = "Coming Soon",
  Ended = "Ended",
  MyCompletedQuests = "My Completed Quests",
}

export type TileFilterOption = PromoTileFilterOption | QuestTileFilterOption;

export const questFilterOptions = Object.values(QuestTileFilterOption);
export const promoFilterOptions = Object.values(PromoTileFilterOption);
