import { LatestEarnings, type RuleId } from "@imx-tokens-ts/gems-game/domain";
import { match } from "ts-pattern";
import type { InjectableFetch } from "./types";

export type WalletLatestEarningsResponse = {
  message: string;
  code: number;
  result: {
    latest_earnings: {
      increase: number;
      type: "on_chain" | "off_chain";
      rule_id: string;
      timestamp: string;
      total: number;
    }[];
  };
};

export const getWalletLatestEarnings = async (
  fetch: InjectableFetch,
  apiUrl: string,
  walletAddress: string,
): Promise<LatestEarnings> => {
  const res = await fetch<WalletLatestEarningsResponse, Error>(
    `${apiUrl}/v1/rewards/gems/${walletAddress}/latest_earnings`,
  );

  return await match(res)
    .with({ ok: true }, async (res) => {
      const body = await res.json();
      const earnings = body.result.latest_earnings.map((earning) => ({
        increase: earning.increase,
        type: earning.type,
        ruleId: earning.rule_id as RuleId,
        timestamp: new Date(earning.timestamp),
        total: earning.total,
      }));

      return new LatestEarnings(earnings);
    })
    .with({ ok: false }, async (res) => {
      const text = await res.text();
      throw new Error(`Error from API: ${text}`);
    })
    .exhaustive();
};
