import { type BrowserProvider, Interface } from "ethers";
import { match } from "ts-pattern";
import type { InjectableFetch } from "./types";

export type WalletGemsResponse = {
  message: string;
  code: number;
  result: {
    gems: number;
    wallet_address: string;
    daily_gems_claimable?: number;
  };
};

export type WalletGems = {
  gems: number;
  walletAddress: string;
  dailyGemsClaimable: number | undefined;
};

const newGemsGameContractInterface = () =>
  new Interface(["function earnGem()"]);

export const earnGem = async (
  provider: BrowserProvider,
  from: string,
  contractAddress: string,
) => {
  await provider.send("eth_sendTransaction", [
    {
      from,
      to: contractAddress,
      data: newGemsGameContractInterface().encodeFunctionData("earnGem"),
    },
  ]);
};

export const getWalletGems = async (
  fetch: InjectableFetch,
  apiUrl: string,
  walletAddress: string,
): Promise<WalletGems> => {
  const res = await fetch(`${apiUrl}/v1/rewards/gems/${walletAddress}`);

  const body = await match(res)
    .with({ ok: true }, async (res) => (await res.json()) as WalletGemsResponse)
    .with({ ok: false }, async (res) => {
      const text = await res.text();
      throw new Error(`Error from API: ${text}`);
    })
    .exhaustive();

  return {
    gems: body.result.gems,
    walletAddress: body.result.wallet_address,
    dailyGemsClaimable: body.result.daily_gems_claimable,
  };
};
