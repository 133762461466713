import { usePassportProvider } from "@/context";
import { notifyError } from "@/utils/monitoring";
import { useCallback } from "react";
import { useConnection } from "./connection";

export function useAccessToken(): () => Promise<string | undefined> {
  const { passportClient } = usePassportProvider();
  const { disconnect } = useConnection();

  const getAccessToken = useCallback(async () => {
    try {
      return await passportClient.getAccessToken();
    } catch (e: unknown) {
      if (e instanceof Error) {
        notifyError(e, "useAccessToken");
      }
      disconnect();
    }
  }, [passportClient, disconnect]);

  return getAccessToken;
}
