import { match } from "ts-pattern";
import type { InjectableFetch } from "./types";

export interface GetWishlistedGamesErrorResponse {
  code: string;
  message: string;
}

export type GetWishListedGamesResponse = {
  results: WishlistedGames;
};

export type WishlistedGames = {
  game_id: string;
}[];

export interface AddToWishlistErrorResponse {
  code: string;
  message: string;
}

export type AddToWishlistResponse = undefined;

export const getWishlistedGames = async (
  fetch: InjectableFetch,
  baseURL: string,
  accessToken: string,
): Promise<string[]> => {
  const wishlistedGamesResponse = await fetch<
    GetWishListedGamesResponse,
    GetWishlistedGamesErrorResponse
  >(`${baseURL}/v1/games/wishlist`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    referrerPolicy: "origin",
    mode: "cors",
  });

  return await match(wishlistedGamesResponse)
    .with({ ok: true }, async (res) => {
      const body = await res.json();
      return body.results.map((game) => game.game_id);
    })
    .with({ ok: false }, async (res) => {
      const body = await res.json();
      throw new Error(body.message);
    })
    .exhaustive();
};

export const addToWishlist = async (
  fetch: InjectableFetch,
  baseURL: string,
  gameId: string,
  accessToken: string,
): Promise<string> => {
  const requestPayload = {
    game_id: gameId,
  };

  // expected response is empty
  const wishlistResponse = await fetch<
    AddToWishlistResponse,
    AddToWishlistErrorResponse
  >(`${baseURL}/v1/games/wishlist`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    referrerPolicy: "origin",
    mode: "cors",
    body: JSON.stringify(requestPayload),
  });

  return await match(wishlistResponse)
    .with({ ok: true }, async (res) => {
      // returns 200 OK with no response body
      return gameId;
    })
    .with({ ok: false }, async (res) => {
      const body = await res.json();
      throw new Error(body.message);
    })
    .exhaustive();
};
