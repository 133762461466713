import { usePassportProvider } from "@/context";
import { notifyError } from "@/utils/monitoring";
import { trackLegacyEvent } from "@analytics";
import * as checkout from "@imtbl/checkout-sdk";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocalStorage } from "usehooks-ts";

const LOCALSTORAGE_KEY = "rewards-provider-type";

export type ProviderType = checkout.WalletProviderName;

type StoredProviderType = ProviderType | null;

export type Connect = (
  provider: checkout.WrappedBrowserProvider,
  providerType: ProviderType,
) => Promise<void>;

export type ConnectionContext = {
  provider: checkout.WrappedBrowserProvider | null; // has to be the checkout one because we send it to the widgets factory
  walletAddress: string | null;
  isConnecting: boolean;
  isPassportWallet: boolean;
  connect: Connect;
  disconnect: () => Promise<void>;
  setIsConnecting: (isConnecting: boolean) => void;
};

type onListener = {
  accountsChanged: (accounts: string[]) => void;
};

interface ProviderWithListener extends checkout.WrappedBrowserProvider {
  ethereumProvider: checkout.EIP1193Provider & {
    on: <T extends keyof onListener>(
      eventName: T,
      handler: onListener[T],
    ) => void;
    removeListener: <T extends keyof onListener>(
      eventName: T,
      handler: onListener[T],
    ) => void;
  };
}

export const ConnectionContext = createContext<ConnectionContext | null>(null);

// https://docs.metamask.io/wallet/reference/provider-api/#errors
const METAMASK_USER_REJECTED_CONNECTION_CODE = 4001;

// https://docs.metamask.io/services/reference/ethereum/json-rpc-methods/#json-rpc-errors
const METAMASK_RESOURCE_UNAVAILABLE_CODE = -32002; // e.g. Already processing eth_requestAccounts. Please wait.

const EXPECTED_METAMASK_ERROR_CODES = [
  METAMASK_USER_REJECTED_CONNECTION_CODE,
  METAMASK_RESOURCE_UNAVAILABLE_CODE,
];

const isPassport = (providerType: ProviderType | null): boolean =>
  providerType === checkout.WalletProviderName.PASSPORT;

const canListenToEvents = (
  provider: checkout.WrappedBrowserProvider,
): provider is ProviderWithListener => {
  return (
    !!provider.ethereumProvider &&
    "on" in provider.ethereumProvider &&
    typeof provider.provider.on === "function" &&
    "removeListener" in provider.ethereumProvider &&
    typeof provider.provider.removeListener === "function"
  );
};

export const ConnectionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  /// State
  const [provider, setProvider] =
    useState<checkout.WrappedBrowserProvider | null>(null);
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [storedProviderType, setStoredProviderType] =
    useLocalStorage<StoredProviderType>(LOCALSTORAGE_KEY, null);
  const [isDisconnecting, setIsDisconnecting] = useState<boolean>(false);
  const {
    triggerLogin,
    zkEvmProvider,
    isLoggedIn,
    walletAddress: passportWalletAddress,
  } = usePassportProvider();

  /// Hooks
  const connect = useCallback(
    async (
      provider: checkout.WrappedBrowserProvider,
      providerType: ProviderType,
    ): Promise<void> => {
      // passport connect is handled via global PassportProvider
      if (isPassport(providerType)) {
        triggerLogin();
        setStoredProviderType(providerType);
        return;
      }

      setIsConnecting(true);

      // deal with EOA
      setProvider(provider);
      try {
        const [connectedWallet]: (string | undefined)[] = await provider.send(
          "eth_requestAccounts",
          [],
        );
        if (!connectedWallet) {
          setIsConnecting(false);
          return;
        }
        setWalletAddress(connectedWallet);
        setStoredProviderType(providerType);

        setIsConnecting(false);
        // biome-ignore  lint/suspicious/noExplicitAny: error contains code
      } catch (e: any) {
        setIsConnecting(false);

        if (!("code" in e) || !EXPECTED_METAMASK_ERROR_CODES.includes(e.code)) {
          notifyError(e, "connectionProviderConnect");
        }
      }
    },
    [setStoredProviderType, triggerLogin],
  );

  const disconnect = useCallback(async () => {
    if (!isDisconnecting) {
      setIsDisconnecting(true);
      trackLegacyEvent({
        userJourney: "Disconnect",
        screen: "ConnectionProvider",
        control: "Disconnect",
        controlType: "Button",
        action: "Pressed",
      });

      setProvider(null);
      setWalletAddress(null);
      // clear the storage on logout
      setStoredProviderType(null);
      setIsDisconnecting(false);
    }
  }, [isDisconnecting, setStoredProviderType]);

  const updateWalletAddress = useCallback(
    async (accounts: string[]) => {
      if (accounts.length) {
        setWalletAddress(accounts[0]);
      } else {
        // if not other active wallet connected to our app, then logout
        if (provider) {
          await disconnect();
        }
      }
    },
    [provider, disconnect],
  );

  // since passport connect is handled via global PassportProvider, hook into the provider and walletAddress changes
  // and update local state
  useEffect(() => {
    if (!isLoggedIn) {
      // if something goes wrong during Passport login/registration, avoids infinite isConnecting
      setIsConnecting(false);
    } else if (isLoggedIn && !passportWalletAddress) {
      // See ENG-315, new Passport users need to wait for a provider to be setup
      setIsConnecting(true);
    } else if (isLoggedIn && passportWalletAddress && zkEvmProvider) {
      setProvider(new checkout.WrappedBrowserProvider(zkEvmProvider));
      setWalletAddress(passportWalletAddress);
      setStoredProviderType(checkout.WalletProviderName.PASSPORT);
      setIsConnecting(false);
    }
  }, [passportWalletAddress, zkEvmProvider, setStoredProviderType, isLoggedIn]);

  // Handle wallet events
  useEffect(() => {
    if (!provider || !canListenToEvents(provider)) {
      return;
    }

    provider.ethereumProvider.on("accountsChanged", updateWalletAddress);

    return () => {
      provider.ethereumProvider.removeListener(
        "accountsChanged",
        updateWalletAddress,
      );
    };
  }, [provider, updateWalletAddress]);

  const connectWithMetamask = useCallback(
    (externalProvider: checkout.WrappedBrowserProvider) =>
      connect(externalProvider, checkout.WalletProviderName.METAMASK),
    [connect],
  );

  // Reconnect to previously connected wallet
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.ethereum &&
      storedProviderType === checkout.WalletProviderName.METAMASK
    ) {
      connectWithMetamask(new checkout.WrappedBrowserProvider(window.ethereum));
    }
  }, [connectWithMetamask, storedProviderType]);

  const providerValues = useMemo(
    () => ({
      provider,
      walletAddress,
      isConnecting,
      isPassportWallet: isPassport(storedProviderType),
      connect,
      disconnect,
      setIsConnecting,
    }),
    [
      provider,
      walletAddress,
      isConnecting,
      storedProviderType,
      connect,
      disconnect,
    ],
  );

  return (
    <ConnectionContext.Provider value={providerValues}>
      {children}
    </ConnectionContext.Provider>
  );
};

export const useConnection = () => {
  const ctx = useContext(ConnectionContext);
  if (!ctx) {
    throw new Error("useConnection must be used within a ConnectionProvider");
  }
  return ctx;
};
