import type { AllIconKeys, BadgeProps } from "@biom3/react";
import { differenceInDays, isFuture, isPast } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { QuestTileFilterOption } from "./FilterType";
import type { LatestEarnings } from "./LatestEarnings";
import type { AvailableOn, Platform, Quest } from "./Quest";

type MediaExtension = "webp" | "svg" | "m3u8" | "png";

export type QuestMediaType = `${string}.${MediaExtension}`;

const daysSince = (startDate: Date) => differenceInDays(new Date(), startDate);

export const getDescriptionFromDates = (
  startDate: Date | undefined,
  endDate: Date | undefined,
): string => {
  if (!startDate) {
    return "";
  }

  if (startDate && isFuture(startDate)) {
    return `Starts at ${formatInTimeZone(
      startDate,
      "UTC",
      "LLL d, HH:mm",
    )} UTC.`;
  }

  if (!endDate || isPast(endDate)) {
    return "";
  }

  return endDateText(endDate);
};

export const formatDate = (date: Date): string =>
  `${formatInTimeZone(date, "UTC", "LLL d, HH:mm")} UTC`;

export const endDateText = (endDate: Date) =>
  `${isFuture(endDate) ? "Ends at" : "Ended"} ${formatInTimeZone(
    endDate,
    "UTC",
    "LLL d, HH:mm",
  )} UTC.`;

export const isNowInBetween = (startDate: Date, endDate: Date) =>
  isPast(startDate) && isFuture(endDate);

export function filterQuest(
  quest: Quest,
  filter: QuestTileFilterOption,
  latestEarnings?: LatestEarnings,
): boolean {
  switch (filter) {
    case QuestTileFilterOption.All:
      return true;
    case QuestTileFilterOption.Live:
      return quest.isLive();
    case QuestTileFilterOption.ComingSoon:
      return quest.isComingSoon();
    case QuestTileFilterOption.Ended:
      return quest.hasEnded();
    case QuestTileFilterOption.MyCompletedQuests:
      return quest.hasCompleted(latestEarnings);
    default:
      return false;
  }
}

export function filterQuests(
  allQuests: Quest[],
  selectedFilter: QuestTileFilterOption,
  latestEarnings?: LatestEarnings,
) {
  if (selectedFilter === QuestTileFilterOption.All) return allQuests;
  return allQuests.filter((quest) =>
    filterQuest(quest, selectedFilter, latestEarnings),
  );
}

export function buildQuestsForAvailableFilterOptions(
  quests: Quest[],
  filterOptions: QuestTileFilterOption[],
  latestEarnings?: LatestEarnings,
): Map<QuestTileFilterOption, Quest[]> {
  return filterOptions.reduce((acc, option) => {
    const filteredQuests = filterQuests(quests, option, latestEarnings);
    if (filteredQuests.length > 0) {
      acc.set(option, filteredQuests);
    }
    return acc;
  }, new Map<QuestTileFilterOption, Quest[]>());
}

export const getBadgeProps = ({
  startDate,
  endDate,
}: {
  startDate: Date | undefined;
  endDate: Date | undefined;
}): Pick<BadgeProps, "badgeContent" | "variant"> | null => {
  if (endDate && isPast(endDate)) {
    return { badgeContent: "Quest Ended", variant: "fatal" };
  }
  if (!startDate) return null;
  if (isFuture(startDate)) return null;
  if (daysSince(startDate) < 5) {
    return { badgeContent: "New Quest", variant: "emphasis" };
  }
  return null;
};

const platformIconMapping: Record<
  Platform,
  {
    icon: AllIconKeys;
    label: string;
  }
> = {
  Mac: {
    icon: "Apple",
    label: "Mac",
  },
  Windows: {
    icon: "Windows",
    label: "Windows",
  },
  iOS: {
    icon: "Apple",
    label: "iOS",
  },
  Android: {
    icon: "GooglePlayStore",
    label: "Android",
  },
  BrowserMobile: {
    icon: "ApplicationMobile",
    label: "Mobile browser",
  },
  BrowserDesktop: {
    icon: "ApplicationWeb",
    label: "Desktop browser",
  },
  EpicStore: {
    icon: "EpicStore",
    label: "Epic Store",
  },
  SteamStore: {
    icon: "SteamStore",
    label: "Steam",
  },
};

export function getPlatformSummary(
  availableOn: AvailableOn | undefined,
): { icons: AllIconKeys[]; summary: string } | null {
  if (!availableOn?.length) {
    return null;
  }

  if (
    availableOn.length === 2 &&
    availableOn.includes("BrowserMobile") &&
    availableOn.includes("BrowserDesktop")
  ) {
    return {
      icons: [
        platformIconMapping.BrowserDesktop.icon,
        platformIconMapping.BrowserMobile.icon,
        "Desktop",
      ],
      summary: "Any Browser",
    };
  }

  return {
    icons: availableOn.map((platform) => platformIconMapping[platform]?.icon),
    summary: availableOn
      .map((platform) => platformIconMapping[platform]?.label)
      .join(", "),
  };
}
