import { type Earning, LatestEarnings } from "@/domain/gems-game/domain";
import type { RewardEarning } from "@/domain/gems-game/domain/lib/Earning";
import { authorizedGet, authorizedPost } from "@/utils/request";
import { appConfig } from "@constants";
import {
  addToWishlist,
  getWalletGems,
  getWalletLatestEarnings,
  getWishlistedGames,
} from "@imx-tokens-ts/gems-game/data-access";
import { useCallback, useEffect, useState } from "react";
import useSWR, { mutate, useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";
import { useAccessToken } from "./accessToken";
import { useAccessTokenState } from "./useAccessToken";

const NATIVE_BALANCE_POLLING_INTERVAL_MS = 6000;

export const useGetWalletGems = (
  walletAddress: string | null,
  pause: boolean,
) => {
  const { data, error, isLoading, mutate } = useSWR(
    walletAddress ? `wallet/gems/${walletAddress}` : null,
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    () => getWalletGems(fetch, appConfig.IMMUTABLE_BASE_URI, walletAddress!),
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      isFetchingLatestEarningsPaused: () => pause,
    },
  );
  return { walletGems: data, error, isLoading, mutate };
};

const rewardsUrl = `${appConfig.IMMUTABLE_BASE_URI}/v1/rewards/gems/`;

// Mutate all gems caches
export const mutateGems = async () => {
  await mutate(
    (key: string) => key?.includes("gems"),
    undefined, // No new data, just revalidate
    { revalidate: true },
  );
};

const defaultChests: RewardEarning[] = [];
export const useChests = () => {
  const accessToken = useAccessTokenState();
  const earningsKey = "/v1/rewards/earnings?reward_type=chest";

  const {
    data = defaultChests,
    error,
    isLoading,
    mutate: mutateChests,
  } = useSWR<RewardEarning[]>(
    accessToken
      ? ["/v1/rewards/earnings?reward_type=chest", accessToken]
      : null,
    ([path, accessToken]) => authorizedGet(path, accessToken as string),
  );

  // Add a useEffect to force revalidation for chests when the token changes
  useEffect(() => {
    if (accessToken) mutate(earningsKey);
  }, [accessToken]);

  const openChest = useCallback(
    (earningId: string) => async () => {
      if (!accessToken) return;
      try {
        await authorizedPost(
          `/v1/earnings/${earningId}/claim`,
          null,
          accessToken,
        );
      } catch (error) {
        console.error("Failed to claim chest", error);
      } finally {
        // Invalidate SWR caches after claim
        await Promise.all([
          mutateChests(),
          mutateGems(),
          mutate(rewardsUrl),
          mutate(earningsKey),
        ]);
      }
    },
    [accessToken, mutateChests],
  );
  return {
    chests: data,
    openChest,
    error,
    isLoading,
    mutate: mutateChests,
  };
};

export const useGetWalletLatestEarnings = (walletAddress?: string) => {
  const [isFetchingLatestEarningsPaused, setIsFetchingLatestEarningsPaused] =
    useState(false);
  const { data, error, isLoading, mutate } = useSWR(
    walletAddress ? `rewards/gems/${walletAddress}/latest_earnings` : null,
    () =>
      getWalletLatestEarnings(
        fetch,
        appConfig.IMMUTABLE_BASE_URI,
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        walletAddress!,
      ).then((response) => {
        return new LatestEarnings(
          response.earnings.sort(
            (a, b) =>
              new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
          ),
        );
      }),
    {
      revalidateIfStale: !isFetchingLatestEarningsPaused,
      revalidateOnFocus: !isFetchingLatestEarningsPaused,
      revalidateOnReconnect: !isFetchingLatestEarningsPaused,
      refreshInterval: isFetchingLatestEarningsPaused ? 0 : 30 * 1000, // 30 seconds
    },
  );

  const updateEarnings = (updatedEarning: Earning) => {
    mutate(
      (latestEarnings) =>
        (latestEarnings ?? new LatestEarnings([])).cloneUpdate(updatedEarning),
      {
        revalidate: false,
      },
    );
  };

  return {
    latestEarnings: data,
    error,
    isLoading,
    updateEarnings,
    mutate,
    toggleEarningsRefresh: setIsFetchingLatestEarningsPaused,
  };
};

export const useGetWishlistedGames = ({
  walletAddress,
  isPassportWallet,
}: {
  walletAddress: string | null;
  isPassportWallet: boolean;
}) => {
  const getAccessToken = useAccessToken();
  const { data, error, isLoading } = useSWR(
    walletAddress && isPassportWallet
      ? `v1/games/wishlist/${walletAddress}`
      : null,
    async (url) => {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        throw new Error("Access token is required to get wishlisted games");
      }
      return getWishlistedGames(
        fetch,
        appConfig.IMMUTABLE_BASE_URI,
        accessToken,
      );
    },
  );
  return { wishlistedGames: data, error, isLoading };
};

// wishlisting
export const useAddToWishlist = () => {
  const getAccessToken = useAccessToken();
  const { mutate } = useSWRConfig();

  const { trigger, error, isMutating } = useSWRMutation(
    `v1/games/wishlist/${Date.now()}`, // we don't want to cache this
    async (
      url,
      {
        arg: { gameId, currentWishlistedGames, walletAddress },
      }: {
        arg: {
          gameId: string;
          currentWishlistedGames: string[];
          walletAddress: string;
        };
      },
    ) => {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        throw new Error("Access token is required to add to wishlist");
      }
      await addToWishlist(
        fetch,
        appConfig.IMMUTABLE_BASE_URI,
        gameId,
        accessToken,
      );
      return mutate(`v1/games/wishlist/${walletAddress}`, [
        ...currentWishlistedGames,
        gameId,
      ]);
    },
    {
      populateCache: false,
    },
  );

  return { addToWishlist: trigger, error, isAddingToWishlist: isMutating };
};
