import { PINATA_GATEWAY_URL, appConfig } from "@/constants";
import {
  type Bid,
  EnvironmentNames,
  type Erc20Token,
  type PricingData,
} from "@/types";
import type * as orderbook from "@imtbl/orderbook";
import type * as passport from "@imtbl/passport";

import { BigNumber } from "bignumber.js";

export function toSimpleAddressFormat(address: string): string {
  return `${address.slice(0, 6)}...${address.slice(-4)}`.toLowerCase();
}

export const isMatchingAddress = (addressA = "", addressB = "") =>
  addressA.toLowerCase() === addressB.toLowerCase();

export const safeTestId = (testId: string): string =>
  `${testId}`.replace(/[^a-zA-Z0-9-]/g, "-");

export const getMediaUrl = (url: string | undefined) => {
  if (url?.startsWith("ipfs://")) {
    const cid = url.split("ipfs://")[1];
    return `${PINATA_GATEWAY_URL}/ipfs/${cid}`;
  }
  return url; // If it's not an IPFS URL, return as is
};

export const convertTokenAmount = (
  amount: string,
  decimals: number,
): { value: string; isApproximate: boolean } => {
  const value = new BigNumber(amount).div(10 ** decimals);
  const decimalPlaces = value.decimalPlaces();

  if (decimalPlaces === null) {
    return { value: "", isApproximate: false };
  }
  if (value.isGreaterThanOrEqualTo(1)) {
    // From 1 and above, show 2 decimal places
    // If there are more than 2 decimal places, set isApproximate to true
    return {
      value: value.toFormat(2),
      isApproximate: decimalPlaces >= 2,
    };
  }

  // Value is between 0 and 1
  if (decimalPlaces <= 5) {
    // 0 to 5 decimal places, no tilda
    return { value: value.toFormat(decimalPlaces), isApproximate: false };
  }
  // More than 5 decimal places, round to 5 and set tilda to true
  return {
    value: value.toFormat(5, BigNumber.ROUND_UP),
    isApproximate: true,
  };
};

export const getZkEvmExplorerUrl = () =>
  appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
    ? "https://explorer.immutable.com"
    : "https://explorer.testnet.immutable.com";

export const flattenToString = (
  value: string | string[] | undefined,
): string => {
  if (value) {
    if (Array.isArray(value)) {
      return value.join(" ");
    }
    return value;
  }
  return "";
};

export const isSandbox = () =>
  appConfig.ENVIRONMENT === EnvironmentNames.SANDBOX;

export const isImmutableUser = (userInfo: passport.UserProfile) => {
  return userInfo.email && userInfo.email.endsWith("@immutable.com") === true;
};

export function timeAgo(
  t: (key: string, options: { count: number }) => string,
  dateString?: string,
): string {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  const now = new Date();
  const diffInMs = now.getTime() - date.getTime(); // Difference in milliseconds

  // Convert milliseconds to days
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInDays < 30) {
    return t("last_sold_days", { count: diffInDays });
  }

  // Convert days to months (approximate)
  const diffInMonths = Math.floor(diffInDays / 30);
  if (diffInMonths < 12) {
    return t("last_sold_months", { count: diffInMonths });
  }

  // Convert months to years
  const diffInYears = Math.floor(diffInMonths / 12);
  return t("last_sold_years", { count: diffInYears });
}

export function getPrice(
  pricingData: PricingData | undefined,
  tokens: Map<string, Erc20Token>,
  getFormattedPrice: (
    symbol: string | undefined,
    decimals: number | undefined,
    amount: string,
  ) => string,
): string | undefined {
  if (!pricingData) return undefined;
  const token =
    pricingData.token.type === "NATIVE"
      ? { decimals: 18, symbol: "IMX" }
      : tokens.get(pricingData.token.contract_address);
  if (token === undefined) {
    return pricingData.amount;
  }
  return getFormattedPrice(token.symbol, token.decimals, pricingData.amount);
}

export const getFormattedNetPrice = (
  bid: orderbook.CollectionBid | Bid,
  tokens: Map<string, Erc20Token>,
): string => {
  // Determine the token address and total amount based on bid type (i.e. CollectionBid or ItemBid)
  const tokenAddress =
    "sell" in bid ? bid.sell[0].contractAddress : bid.token_address;
  const totalAmount = BigInt("sell" in bid ? bid.sell[0].amount : bid.amount);

  // Calculate total fees for types PROTOCOL and ROYALTY
  const totalFees = (bid.fees || [])
    .filter((fee) => fee.type === "PROTOCOL" || fee.type === "ROYALTY")
    .reduce((acc, fee) => acc + BigInt(fee.amount), BigInt(0));

  const netAmount = totalAmount - totalFees;

  let quantity = BigInt(1); // Default to 1 for ERC721 or if quantity is not specified
  if (
    ("sell" in bid && bid.buy[0].type === "ERC1155_COLLECTION") ||
    ("sell" in bid && bid.buy[0].type === "ERC721_COLLECTION")
  ) {
    // For ERC1155 CollectionBid & ERC721 CollectionBid
    quantity = BigInt(bid.buy[0].amount ?? "1");
  }

  const netUnitAmount = netAmount / quantity;

  const token = tokens.get(tokenAddress);
  const tokenAmount = convertTokenAmount(
    netUnitAmount.toString(),
    token?.decimals ?? 18,
  );

  return token
    ? `${token.symbol} ${tokenAmount.value}`
    : netUnitAmount.toString();
};

// Utility function to throttle function calls
export const throttle = <T extends (...args: unknown[]) => unknown>(
  func: T,
  delay: number,
): ((...args: Parameters<T>) => void) => {
  let lastCall = 0;
  let throttled = false;

  return (...args: Parameters<T>) => {
    const now = Date.now();

    // If we're not currently throttled, execute immediately
    if (!throttled) {
      func(...args);
      lastCall = now;
      throttled = true;

      // Set a timeout to allow the next execution after delay
      setTimeout(() => {
        throttled = false;
      }, delay);
    }
  };
};

export const delay = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));
